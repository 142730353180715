import ButtonLink from './buttons/ButtonLink'
import React from 'react'
import { css } from '@emotion/react'
import theme from '../../theme'

const callToAction = css`
  background-color: ${theme.palette.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  text-align: center;
  font-size: 22px;
  color: #ffffff;

  .calToActionBtn {
    text-decoration: none;
    font-size: 16px;
    color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 8px 16px;
    margin-left: 30px;
    transition: all 0.25s ease-in-out;
    min-width: 162px;

    &:hover {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
`

const CallToAction = ({ children, buttonLink, buttonText }) => (
  <div css={callToAction}>
    {children}
    <ButtonLink to={buttonLink} variant="primary" className="calToActionBtn">
      {buttonText}
    </ButtonLink>
    {/*<Link className="calToActionBtn">
      Shop now{'  '}
      <FontAwesomeIcon icon={faShoppingCart} />
    </Link>*/}
  </div>
)

export default CallToAction
