import { graphql, useStaticQuery } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../layout/Section'
import SectionHeader from '../elements/SectionHeader'
import ServiceCard from '../elements/cards/ServiceCard'

const SERVICES = [
  {
    title: 'Beratung',
    subtitle:
      'Gemeinsam mit Ihnen entwickeln wir ein auf Ihre Bedürfnisse zugeschnittenes Design-Konzept.',
    image: 'consulting',
  },
  {
    title: 'Logo Design',
    subtitle:
      'Beim Logodesign strebe ich danach, das perfekte Markenzeichen für Sie zu schaffen.',
    image: 'logo-design',
  },
  {
    title: 'Social Media',
    subtitle:
      'Alles, was mit den speziellen Anforderungen von Social-Media-Plattformen zu tun hat.',
    image: 'social-media',
  },
  {
    title: 'Animation',
    subtitle:
      'Bei der Animation setze ich Bilder, Figuren oder Illustrationen in ein Bewegbild um.',
    image: 'animation',
  },
  {
    title: 'Geschäftsausstattung',
    subtitle:
      'Ich gestalte Visitenkarten, Briefpapiere und Rechnungsformulare für Ihr Unternehmen.',
    image: 'business-equipment',
  },
  {
    title: 'Brand Identity',
    subtitle:
      'Aufgrund Ihrer persönlichen Ziele entwickle ich Ihre individuelle Designstrategie.',
    image: 'brand-identity',
  },
  {
    title: 'Werbung',
    subtitle:
      'Aufmerksamkeitserregende Entwürfe und Designs für Ihr Werbeprojekt.',
    image: 'ads',
  },
  {
    title: 'Und vieles mehr!',
    subtitle:
      'Ich bin mir sicher, dass ich Ihnen auch bei anderen Designwünschen helfen kann.',
    image: 'more',
  },
]

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/home/services/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 120, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <Section backgroundColor="#fff">
      <SectionHeader title="Meine Dienstleistungen">
        Dies sind einige meiner Designangebote, die ich meinen Kunden zur
        Verfügung stelle.
      </SectionHeader>

      <Container>
        <Row>
          {SERVICES.map(s => (
            <Col md={6} lg={3} key={s.image}>
              <ServiceCard
                title={s.title}
                subtitle={s.subtitle}
                image={
                  data.allFile.nodes.find(f => f.name === s.image)
                    .childImageSharp
                }
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default ServicesSection
