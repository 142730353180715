import ButtonLink from '../../elements/buttons/ButtonLink'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../../layout/Section'
import { StaticImage } from 'gatsby-plugin-image'

const BasicInformationSection = () => (
  <Section>
    <Row>
      <Col md={6}>
        <StaticImage
          src="../../../assets/img/pages/home/contact.png"
          quality={100}
          placeholder="blurred"
          alt="Miteinander verbundene Logos von TeamSpeak, Discord und WhatsApp"
        />
      </Col>
      <Col md={6} className="vertical-center">
        <div>
          <h1>Kontaktaufnahme</h1>
          <p>
            Um sowohl Privatpersonen als auch Unternehmen eine einfache und
            formlose Möglichkeit zu bieten mit mir in Kontakt zu treten, biete
            ich alternativ zur E-Mail auch Kontaktmöglichkeiten wie WhatsApp
            oder Discord an.
          </p>

          <p>
            Für Fragen und Beratungen zu Ihrem Designprojekt stehe ich Ihnen
            gerne zur Verfügung und freue mich darauf, gemeinsam mit Ihnen eine
            Lösung für die Umsetzung Ihres nächsten Designprojekts zu finden.
          </p>
          <ButtonLink variant="primary" to="/kontakt/">
            Kontakt
          </ButtonLink>
        </div>
      </Col>
    </Row>
  </Section>
)

export default BasicInformationSection
