import IconCirle from './IconCircle'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../theme'

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;

  h1 {
    margin: 20px 0 0 0;
    font-size: 3rem;
    ${props => (props.dark ? `color: ${theme.palette.white};` : '')}
  }

  p {
    color: ${props => (props.dark ? theme.palette.darkWhite : 'inherit')};
  }
`

const SectionHeader = ({ icon, title, dark, children }) => (
  <StyledSectionHeader dark={dark}>
    {icon && <IconCirle icon={icon} />}
    <h1>{title}</h1>
    <p>{children}</p>
  </StyledSectionHeader>
)

export default SectionHeader
