import './css/owl.css'
import './css/owl-theme.css'

import OwlCarousel from 'react-owl-carousel2'
import React from 'react'

const GenericCarousel = ({ options, events, children }) => (
  <OwlCarousel options={options} events={events}>
    {children}
  </OwlCarousel>
)

export default GenericCarousel
