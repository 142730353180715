import BaseLayout from '../components/layout/BaseLayout'
import BasicInformationSection from '../components/sections/home/BasicInformationSection'
import CallToAction from '../components/elements/CallToAction'
import MyWorkSection from '../components/sections/home/MyWorkSection'
import React from 'react'
import ServicesSection from '../components/sections/ServicesSection'
import SliderSection from '../components/sections/SliderSection'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const IndexPage = () => (
  <BaseLayout>
    <SliderSection />
    <BasicInformationSection />
    <ServicesSection />
    <MyWorkSection />
    <CallToAction buttonLink="/kontakt/" buttonText="Kontakt">
      Sie sind bereit loszulegen? Dann lassen Sie mir doch eine Nachricht
      zukommen!
    </CallToAction>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Home')}</title>
    <meta
      name="description"
      content="Logos, Visitenkarten, Brandings, Socialmedia-Design und weitere Grafik-Design Lösungen aus Heidelberg. Kontaktiere EisDesigns noch heute!"
    />
  </>
)

export default IndexPage
