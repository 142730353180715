import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { css } from '@emotion/react'
import theme from '../../theme'

const iconCircle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.palette.blue};
  color: ${theme.palette.blue};
  font-size: 30px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
`

const IconCircle = ({ icon, ...props }) => (
  <div css={iconCircle}>
    <FontAwesomeIcon icon={icon} {...props} />
  </div>
)

export default IconCircle
