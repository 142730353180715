import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledButton } from './Button'

const StyledHrefLink = StyledButton.withComponent('a')

const StyledGatsbyLink = StyledButton.withComponent(Link)

const ButtonLink = ({ external, children, ...props }) => {
  const LinkComponent = external
    ? React.createElement(StyledHrefLink, props, children)
    : React.createElement(StyledGatsbyLink, props, children)

  return LinkComponent
}

ButtonLink.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary']),
  large: PropTypes.bool,
  round: PropTypes.bool,
  block: PropTypes.bool,
  addShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

ButtonLink.defaultProps = {
  variant: 'default',
  large: false,
  round: false,
  block: false,
  addShadow: false,
  disabled: false,
  loading: false,
}

export default ButtonLink
