import React, { useEffect, useState } from 'react'

import GenericCarousel from '../elements/carousels/GenericCarousel'
import { StaticImage } from 'gatsby-plugin-image'
import breakpoints from '../../lib/breakpoints'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '../../theme'

const SliderElement = ({ children }) => {
  const [maxHeight, setMaxHeight] = useState(800)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMaxHeight(
        window.innerHeight - theme.measurements.navigation.desktop.height
      )
    }
  }, [setMaxHeight])

  return (
    <div
      css={css`
        max-height: ${maxHeight}px;
      `}
    >
      {children}
    </div>
  )
}

const SliderOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 40px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  h1 {
    font-size: 4rem !important;
    color: #ffffff !important;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    text-align: center;
  }

  p {
    font-size: 1.5rem;
    color: #d3d3d3;
    margin-top: 10px;
    font-weight: 400;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    text-align: center;
  }

  @media screen and (max-width: 992px) {
    h1 {
      font-size: 1.4rem !important;
    }

    p {
      font-size: 1rem !important;
    }
  }
`

const SliderSection = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 15000,
    responsive: {
      0: {
        dots: false,
      },
      [breakpoints.md]: {
        dots: false,
      },
      [breakpoints.xl]: {
        dots: true,
      },
    },
  }

  return (
    <GenericCarousel options={options}>
      <SliderElement>
        <StaticImage
          src="../../assets/img/pages/home/slides/slider-1.jpg"
          quality={100}
          loading="eager"
          placeholder="blurred"
          alt=""
          role="presentation"
        />
        <SliderOverlay>
          <h1>Neue Ideen für alles, was Sie tun.</h1>
          <p>
            Kreative Ideen, die Sie mit außergewöhnlichem Design weiterbringen.
          </p>
        </SliderOverlay>
      </SliderElement>
      <SliderElement>
        <StaticImage
          src="../../assets/img/pages/home/slides/slider-2.jpg"
          quality={100}
          loading="eager"
          placeholder="blurred"
          alt=""
          role="presentation"
        />
        <SliderOverlay>
          <h1>Machen Sie es unkompliziert!</h1>
          <p>
            Nutzen Sie eine der formlosen Kontaktmöglichkeiten, um mit mir in
            Verbindung zu treten.
          </p>
        </SliderOverlay>
      </SliderElement>
      <SliderElement>
        <StaticImage
          src="../../assets/img/pages/home/slides/slider-3.jpg"
          quality={100}
          loading="eager"
          placeholder="blurred"
          alt=""
          role="presentation"
        />
        <SliderOverlay>
          <h1>Holen Sie noch mehr aus sich heraus!</h1>
          <p>
            Design völlig neu gedacht. Vielfältige Designs die auf Sie
            zugeschnitten sind.
          </p>
        </SliderOverlay>
      </SliderElement>
      <SliderElement>
        <StaticImage
          src="../../assets/img/pages/home/slides/slider-4.jpg"
          quality={100}
          loading="eager"
          placeholder="blurred"
          alt=""
          role="presentation"
        />
        <SliderOverlay>
          <h1>Designs für Gamer und Streamer.</h1>
          <p>
            Heben Sie sich mit Ihrem eigenen Stil von der Masse ab. Erwecken Sie
            Ihre Visionen mit mir zum Leben.
          </p>
        </SliderOverlay>
      </SliderElement>
    </GenericCarousel>
  )
}

export default SliderSection
