import ButtonLink from '../../elements/buttons/ButtonLink'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../../layout/Section'
import { StaticImage } from 'gatsby-plugin-image'

const MyWorkSection = () => (
  <Section>
    <Row>
      <Col md={6} className="vertical-center">
        <div>
          <h1>Meine Arbeit</h1>
          <p>
            Als Grafikdesigner habe ich bereits an den verschiedensten
            Designprojekten meiner Kunden gearbeitet. In meinem Portfolio zeige
            ich Ihnen daher einige meiner vergangenen Projekte und Designs.
          </p>

          <p>
            Ich biete Ihnen ein breites Spektrum an verschiedenen
            Designbereichen wie Social-Media bis hin zu klassischen
            Printprodukten.
          </p>

          <p>
            Wenn Sie auf der Suche nach einem kreativen Grafikdesigner sind,
            dann überzeugen Sie sich selbst von meinen Fähigkeiten und nehmen
            Sie sich die Zeit, einige meiner Arbeiten in Ruhe anzuschauen.
          </p>
          <ButtonLink
            variant="primary"
            href="https://eisdesigns.myportfolio.com"
            target="_blank"
            rel="noopener noreferrer"
            external
          >
            Portfolio ansehen
          </ButtonLink>
        </div>
      </Col>
      <Col md={6}>
        <StaticImage
          src="../../../assets/img/pages/home/mywork.png"
          quality={100}
          placeholder="blurred"
          alt="Drei verschiedene Logo-Designs"
        />
      </Col>
    </Row>
  </Section>
)

export default MyWorkSection
