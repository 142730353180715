import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '../../../theme'

const StyledServiceCard = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 4rem;

  strong {
    font-size: 1.1rem;
    color: ${theme.palette.darkGrey};
    margin: 0;
  }

  p {
    color: ${theme.palette.grey};
    margin: 10px 0 0 0;
  }

  .service-card-body {
    margin-top: 1.1rem;
    text-align: center;
  }
`

const ServiceCard = ({ title, subtitle, image }) => (
  <StyledServiceCard>
    <GatsbyImage
      image={getImage(image)}
      alt={title}
      css={css`
        margin: auto;
      `}
    />
    <div className="service-card-body">
      <strong>{title}</strong>
      <p>{subtitle}</p>
    </div>
  </StyledServiceCard>
)

export default ServiceCard
